import React from 'react';

import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Resume from '../components/sections/Resume';
import Experience from '../components/sections/Experience';
import Education from '../components/sections/Education';
import Skills from '../components/sections/Skills';
import Projects from '../components/sections/Projects';
import Certificates from '../components/sections/Certificates';
import config from '../../config';

const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      
      <Resume
        firstName={config.firstName}
        lastName={config.lastName}
        address={config.address}
        email={config.email}
        socialLinks={config.socialLinks}
      >
        Desenvolvedor com mais de 10 anos de experiência, com atuação em todas 
        as etapas do ciclo de desenvolvimento, incluindo levantamento de 
        requisitos, codificação, revisão de código, elaboração de testes 
        automatizados, documentação e implantação. Experiência profissional nas 
        linguagens PL/SQL, Centura, C# e Delphi e na metodologia Scrum. 
        Estudando, com grande interesse, Node.js, React e React Native, 
        principalmente com a utilização de TypeScript.
      </Resume>

      <hr className="m-0" />

      <Projects />

      <hr className="m-0" />

      <Certificates />

      <hr className="m-0" />

      <Experience />

      <hr className="m-0" />

      <Education /> 
    </div>
  </Layout>
);

export default IndexPage;

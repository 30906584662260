import React from 'react';

const Education = () => {

    return (
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="awards"
      >
        <div className="w-100">
          <h2 className="mb-5">Cursos e Certificados</h2>
          
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Bootcamp GoStack</h3>
              <div className="subheading mb-3">Rocketseat</div>
              <p><a href="https://app.rocketseat.com.br/certificates/04a9f14c-ce7f-4a5b-9e5a-17d6cc6645f2">Certificado</a></p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2020 - 2021</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Lista Cursos</h3>
              <div className="subheading mb-3">Alura</div>
              <p><a href="https://cursos.alura.com.br/user/gabrielgianelli/fullCertificate/67d4b0e0d116a953ae3c361e5de97048">Certificados</a></p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2016 - Até o momento</span>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Education;
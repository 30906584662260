import DevIcon from 'devicon-react-svg';
import styled from 'styled-components';

const StyledIcon = styled(DevIcon)`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

export default StyledIcon;
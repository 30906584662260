import React from 'react';

const Experience = () => {

    return (
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experiência</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Analista de Desenvolvimento de Software Sênior</h3>
              <div className="subheading mb-3">TOTVS</div>
              <ul>
                <li>Participação na análise de requisitos do ERP com equipe alocada no cliente.</li>
                <li>Desenvolvimento e manutenção de aplicações em Centura.</li>
                <li>Desenvolvimento e manutenção de processos em PL/SQL.</li>
                <li>Suporte ao usuário.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Outubro 2012 - Até o momento</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Desenvolvedor</h3>
              <div className="subheading mb-3">Luz Soluções Financeiras</div>
              <ul>
                <li>Modelagem de sistemas financeiros de análise e gestão de risco.</li>
                <li>Desenvolvimento e manutenção de sistemas nas linguagens Delphi, C# e C++, utilizando metodologia Scrum.</li>
                <li>Desenvolvimento de scripts para manutenção da base de dados Oracle.</li>
                <li>Elaboração e desenvolvimento de testes automatizados.</li>
                <li>Documentação das soluções desenvolvidas e suporte interno.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Fevereiro 2011 - Abril 2012</span>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Experience;
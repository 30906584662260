import React from 'react';
import StyledIcon from '../icons/StyledIcon';

const Projects = () => {

    return (
<section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="projects"
      >
        <div className="w-100">
          <h2 className="mb-5">Projetos</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">GoBarber</h3>
              <img src="https://github.com/gabrielgianelli/go-barber/blob/master/GoBarberBanner.png?raw=true" alt="GoBarber Banner" />
              <p>Aplicação para agendamento de horário para serviços de cabeleireiros. A versão web permite o gerenciamento da agenda do prestador de serviço, enquanto que o app mobile permite que usuários agendem um horário com um prestador cadastrado.</p>
              <p>Projeto foi desenvolvido durante participação no Bootcamp GoStack da Rocketseat.</p>
              <br /><br />

              <div className="subheading mb-3">Web</div>
              <p>Acesse em: <a href="https://gobarber.gabrielgianelli.dev">https://gobarber.gabrielgianelli.dev</a></p>
              <img src="https://media.giphy.com/media/eM3zYNDVsYOOikjq6V/giphy.gif" alt="GoBarber Web"/>
              <br /><br />

              <div className="subheading mb-3">App Android</div>
              <p>Baixe em: <a href="https://play.google.com/store/apps/details?id=com.gabrielgianelli.appgobarber">https://play.google.com/store/apps/details?id=com.gabrielgianelli.appgobarber</a></p>
              <img src="https://play-lh.googleusercontent.com/E0wqM-V4IzbaERTGqVcIt2MoLYK6PPllt_JHxFRdTikomMB-nD-8iFCUsZQXTVR5l3NP=w720-h310-rw" alt="GoBarber Web"/>
              <img style={{marginLeft:"5px"}} src="https://play-lh.googleusercontent.com/Dddm2DijhiNIekznjycRjoNYEvzG1kh_2h-y0Rh7-r-xpq2F9Cf4iCNVXOVht3S6JMc=w720-h310-rw" alt="GoBarber Web"/>
              <img style={{marginLeft:"5px"}} src="https://play-lh.googleusercontent.com/zre910MyRu5ZIncBfOvf7CHeCfmdiw2smo24glNVpeAi_fTLeXmA5889ZXyr-f8ekQ=w720-h310-rw" alt="GoBarber Web"/>
              <img style={{marginLeft:"5px"}} src="https://play-lh.googleusercontent.com/lbRyQvkF8i664A5k3WwZCeuKCQDlic0BFewM4-GUBfbdgRbZfETqZolONKyI-hDrNF6o=w720-h310-rw" alt="GoBarber Web"/>
              <br /><br />

              <div className="subheading mb-3">API</div>
              <p>Acesse em: </p>
              <a href="https://insomnia.rest/run/?label=GoBarber%20API&uri=https%3A%2F%2Fraw.githubusercontent.com%2Fgabrielgianelli%2Fgo-barber%2Fmaster%2Fbackend%2FInsomnia.json" target="_blank"><img src="https://insomnia.rest/images/run.svg" alt="Run in Insomnia" /></a>
              <br /><br />

              <div className="subheading mb-3">Código Fonte</div>
              <p>Acesse em: <a href="https://github.com/gabrielgianelli/go-barber">https://github.com/gabrielgianelli/go-barber</a></p> 
              <br /><br />

              <div className="subheading mb-3">Tecnologias Utilizadas</div>
              <uL style={{listStyleType:"none"}} >
                <li><StyledIcon icon="typescript_badge"/>TypeScript</li>
                <li><StyledIcon icon="nodejs"/>NodeJS</li>
                <li><StyledIcon icon="jest"/>Jest</li>
                <li><StyledIcon icon="react"/>ReactJS / React Native</li>
                <li><StyledIcon icon="docker"/>Docker</li>
                <li><StyledIcon icon="postgresql"/>PostgreSQL</li>
                <li><StyledIcon icon="mongodb"/>MongoDB</li>
                <li><StyledIcon icon="redis"/>Redis</li>
                <li><StyledIcon icon="aws"/>Amazon S3 / SES</li>
                <li><StyledIcon icon="google-cloud-platform"/>Google Cloud Platform</li>
                <li><StyledIcon icon="github_badge"/>Github Actions</li>
              </uL>
              <br />
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2020 - 2021</span>
            </div>
          </div>

        </div>
      </section>
    );
}

export default Projects;